var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',_vm._l((_vm.libraryPermissions),function(ref,i){
var name = ref.name;
var id = ref.id;
var isShown = ref.isShown;
var values = ref.values;
return _c('v-row',{key:i,staticClass:"ml-6 mr-10 my-8"},[_c('v-icon',{staticClass:"align-start",attrs:{"size":"44"}},[_vm._v(" mdi-library ")]),_c('div',{staticClass:"pa-0 col-sm-11 col-12 pl-3 align-center"},[_c('v-row',{staticClass:"align-center pt-3",attrs:{"no-gutters":""}},[_c('v-icon',{staticClass:"mr-1 cursor-pointer",on:{"click":function($event){return _vm.setIsShown(id, !isShown)}}},[_vm._v(" mdi-chevron-down ")]),_vm._v(" "+_vm._s(name)+" ")],1),(isShown)?_vm._l((values),function(permission){return _c('v-row',{key:permission.id,staticClass:"ml-6 mt-4 flex-nowrap",attrs:{"no-gutters":""}},[_c('AvatarImage',{attrs:{"avatar":_vm.avatars[permission.picture],"avatar-key":permission.picture,"color":permission.id,"add-additional-size-to-icon":false,"size":40}}),_c('v-col',{staticClass:"pa-0 d-flex",class:{'pl-3': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"sm":"11","cols":"12"}},[_c('v-row',{staticClass:"ma-0 d-flex justify-space-between align-center"},[_c('UserInfo',{attrs:{"user-info":permission}}),_c('v-tooltip',{attrs:{"content-class":"btn-tooltip","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('div',_vm._g({staticClass:"d-flex"},onTooltip),[_c('p',{staticClass:"small-p title-in-modal mb-0 pointer text-capitalize"},[_vm._v(" "+_vm._s(permission.libraryGrant.type)+" ")])])]}}],null,true)},[_c('span',[_vm._v("Go to the library to edit library sharing permissions")])])],1)],1)],1)}):_vm._e()],2)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }