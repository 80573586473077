<template>
  <fragment>
    <v-row
      v-for="({ name, id, isShown, values }, i) in libraryPermissions"
      :key="i"
      class="ml-6 mr-10 my-8">
      <v-icon
        class="align-start"
        size="44">
        mdi-library
      </v-icon>
      <div class="pa-0 col-sm-11 col-12 pl-3 align-center">
        <v-row
          class="align-center pt-3"
          no-gutters>
          <v-icon
            class="mr-1 cursor-pointer"
            @click="setIsShown(id, !isShown)">
            mdi-chevron-down
          </v-icon>
          {{ name }}
        </v-row>
        <template v-if="isShown">
          <v-row
            v-for="permission in values"
            :key="permission.id"
            class="ml-6 mt-4 flex-nowrap"
            no-gutters>
            <AvatarImage
              :avatar="avatars[permission.picture]"
              :avatar-key="permission.picture"
              :color="permission.id"
              :add-additional-size-to-icon="false"
              :size="40" />
            <v-col
              class="pa-0 d-flex"
              sm="11"
              cols="12"
              :class="{'pl-3': $vuetify.breakpoint.mdAndUp }">
              <v-row class="ma-0 d-flex justify-space-between align-center">
                <UserInfo :user-info="permission" />
                <v-tooltip
                  content-class="btn-tooltip"
                  bottom>
                  <template #activator="{ on: onTooltip }">
                    <div
                      class="d-flex"
                      v-on="onTooltip">
                      <p class="small-p title-in-modal mb-0 pointer text-capitalize">
                        {{ permission.libraryGrant.type }}
                      </p>
                    </div>
                  </template>
                  <span>Go to the library to edit library sharing permissions</span>
                </v-tooltip>
              </v-row>
            </v-col>
          </v-row>
        </template>
      </div>
    </v-row>
  </fragment>
</template>
<script>
import { mapState } from 'vuex';
export default {
  name: 'ShareContentCollectionLibraries',
  components: {
    UserInfo: () => import('@/components/App/AppShareModal/AppShareModalUserInfo'),
  },
  props: {
    libraryPermissionsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showPermissionsList: false,
      libraryPermissions: [],
    };
  },
  computed: {
    ...mapState([
      'avatars',
    ]),
  },
  watch: {
    libraryPermissionsList: {
      handler(libraryPermissionsList) {
        this.libraryPermissions = libraryPermissionsList.map(library => ({
          ...library,
          isShown: false,
        }));
      },
      immediate: true,
    },
  },
  methods: {
    setIsShown(id, isShown) {
      this.libraryPermissions = this.libraryPermissions.map(library => {
        if (library.id == id) {
          return {
            ...library,
            isShown,
          };
        }
        return library;
      });
    },
  },
};
</script>
<style scoped>
</style>
